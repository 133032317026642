<template>
  <div class="text-center">
    <v-dialog v-model="show" width="1200" persistent>
      <v-card>
        <v-overlay :absolute="true" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
           <v-toolbar color="#00439B" flat>
            <v-toolbar-title class="white--text">Transaction Details</v-toolbar-title>
        </v-toolbar>
         <div class="px-3 border-bottom">
          <v-row class="mt-2" dense>
            <v-col cols="12" md="3" lg="3">
              <small class="text-content text-secondary text-uppercase d-block"> Date of Transaction </small>
              <p class="mb-1 font-weight-bold">
                <span v-if="arrays.accounting">  {{  arrays.accounting.date_time | displayDatev2  }} </span>
                <span v-else> Loading.. </span>
               </p>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <small class="text-content text-secondary text-uppercase d-block"> Paypal </small>
              <p class="mb-1 font-weight-bold"> {{ arrays.paypal ? arrays.paypal.amount : 0  | currency(true, true) }} </p>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <small class="text-content text-secondary text-uppercase d-block"> Wallet </small>
              <p class="font-weight-bold"> {{ arrays.wallet ? arrays.wallet.amount : 0  | currency(true, true) }} </p>
            </v-col>
            <v-col cols="12" md="3" lg="3" class="text-right">
              <small class="text-content text-secondary text-uppercase d-block"> Total Payment </small>
              <p class="h5 black--text font-weight-bold mb-2"> {{ arrays.accounting ? arrays.accounting.amount : 0 | currency(true) }}  </p>
            </v-col>
          </v-row>
        </div>
        <div v-if="recievedData">
          <v-data-table :headers="headers" :items="arrays.subscribers"  :loading="loadings.subscriber" disable-sort id="table" >
            <template v-slot:[`item.name`]="{ item }">
              {{item.user_company.user.first_name}} {{item.user_company.user.last_name}} <span class="font-weight-bold" v-if=" arrays.accounting && item.user_company.user.id == arrays.accounting.user_id"> ( You ) </span>
            </template>
            <template v-slot:[`item.start`]="{ item }">
              {{item.start_of_subscription  | displayDatev2 }} 
            </template>
            <template v-slot:[`item.end`]="{ item }">
             {{item.end_of_subscription  | displayDatev2 }} 
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{item.subscription_amount  | currency(true) }} 
            </template>
          </v-data-table>
          <!-- <Pagination v-bind:meta="pagination.meta" @page="getSubscribers"> </Pagination> -->
        </div>
       
        <v-card-actions  class="border-top">
          <div class="w-100 text-right">
            <v-btn color="#0A009B" text @click="cancel">close</v-btn>
          </div>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
// import Pagination from "@/components/Pagination"
export default {
  components: {
    // Pagination,
  },
  props: {
    dialog: Boolean,
    recievedData: Object,
  },
  data() {
    return {
      overlay: false,
      form: {
        id: null,
        company_id: null,
        user_company_id: null,
        name: null,
        percentage: null,
        type: null,
        disturb: null,
        company_name: null,
        users: null,
      },
      pagination: {
        meta: null,
      },
      arrays : {
        accounting : null,
        wallet : null,
        paypal : null,
        subscribers : [],
      },
      loadings : {
        subscribers : true
      },
      subscribers: [],
      status : {
        accounting : true,
        open : true
      },
      headers : [
        {
          text : 'Name',
          value : 'name',
        },
        {
          text : 'Start of subscription',
          value : 'start',
        },
        {
          text : 'Expiration',
          value : 'end',
        },
        {
          text : 'Amount',
          value : 'amount',
        }
      ],
      
    };
  },
  computed: {
    show: {
      get() {
        if (this.recievedData && this.dialog) {
          this.populate();
        }
        return this.dialog;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancel() {
     
      this.status.accounting = true
      this.arrays.subscribers = []
      this.arrays.paypal = null,
      this.arrays.wallet = null,
      this.arrays.accounting = null
      this.pagination.meta = null
      this.show = false
    },
    remove() {
      this.overlay = true;
      this.axios.delete("/projects/" + this.form.id).then(() => {
        this.overlay = false;
        this.cancel();
        this.$emit("refresh", true);
      });
    },
    populate() {
      if(this.recievedData && this.status.open)
      {
        this.overlay = true
        this.getSubscribers()
      }
    },
    getSubscribers(page)
    {
      this.loadings.subscribers = true
      let p = "";
        if (page) {
          p = page;
        }
      this.axios
          .get(
            "subscriptions?accounting_id=" +
              this.recievedData.id  +
              "&per_page=" + p 
          )
          .then(({ data }) => {
            this.arrays.subscribers = data.subscription;
            if(!this.pagination.meta)
            {
              this.pagination.meta = data.meta;
            }
            this.axios
              .get(
                "subscriptions?accounting_id=" +
                  this.recievedData.id +
                  "&per_page=" +
                  this.pagination.meta.total
              )
              .then(({ data }) => {
                this.arrays.subscribers = data.subscription;
                this.getAccount(this.recievedData.transaction_id)
                this.loadings.subscribers = false
              })
          });
    },
    getAccount(transaction_id)
    {
      this.axios.get('/accounting?transaction_id='+transaction_id) 
      .then( ({data}) => {
        if(data.accounting && data.accounting.length > 0)
        {
          for( let i in data.accounting)
          {
            if(!isNaN(i) )
            {
              if(data.accounting[i].account_id == '1001')
              {
                this.arrays.paypal = data.accounting[i]
              }
              if(data.accounting[i].account_id == '2001' && data.accounting[i].amount < 0)
              {
                this.arrays.wallet = data.accounting[i]
              }
              if(data.accounting[i].account_id == '3001')
              {
                this.arrays.accounting = data.accounting[i]
              }
            }
          }
        }
      })
      .finally( () => {
          this.overlay = false
      })
    },
  },
};
</script>

<style scoped type="text/css">
.subscribers {
  text-transform: none;
}
  .v-data-table-header-mobile{
    display: none!important;
  }
</style>